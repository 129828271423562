import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import EngageIcon from "../../static/images/engage.png";
import SpaceIcon from "../../static/images/space.png";
import RecruitIcon from "../../static/images/recruit.png";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import { 
  HeaderH1,
  HeaderH3,
  ResponsiveGrid,
  SectionH3,
  SectionH4,
  SectionP,
  PartnerLogo
} from "../helpers/styledComponents";
import { Grid } from "semantic-ui-react";
import { IAllianceCompany } from "./index";

const headerContent = (
  <>
    <HeaderH1>Election Day of Service</HeaderH1>
    <HeaderH3 style={{ margin: "1.5rem 0 .75rem" }}>The business community's opportunity to support election infrastructure and election workers.</HeaderH3>
  </>
);

export const FoundingDayGrid = styled(ResponsiveGrid)`
  &&&& {
    margin: -.75em;
    @media screen and (min-width: 1024px) {
      .column {
          width: 25%;
      }
    }
  }
`;

export const StyleH4 = styled.h4`
  max-width: 750px;
  margin: 1em auto 2.5em;
  font-weight: 400;
  font-size: 1.5em;
  @media (max-width: 769px) {
    font-size: 1em;
  }
`;

const StyledA = styled.a`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledGrid = styled(ResponsiveGrid)`
  margin-bottom: 1em;
`;

interface IProps {
  data: {
    allContentfulCivicAllianceCompany: {
      nodes: Array<IAllianceCompany>;
    };
  }
}

export default ({ data } : IProps ) => {
  return (
    <Layout mode="dark" headerContent={headerContent} title="Election Day of Service | Civic Alliance">
      <Section mode="light">
        <SectionH3><u>What</u></SectionH3>
        <SectionP justify>
          Election Day of Service is not just a day, but an ongoing effort that offers companies the opportunity to show their commitment to fair, safe, and transparent elections by supporting election operations in a nonpartisan and tangible way. Each year, companies participate in this effort to address the needs of elections by recruiting poll workers, offering physical spaces for voting centers, and providing legal support for election workers who come under threat.
        </SectionP>
        <SectionH3><u>Why</u></SectionH3>
        <SectionP justify>
          Each year comes with its own set of challenges and events that can impact election operations. From the impacts of the COVID-19 pandemic to a deeply polarized society, election infrastructure continues to experience issues with finding and staffing polling places and threats to officials in charge of elections. We believe the business community is uniquely positioned to surmount these challenges by supporting elections through their resources, reach, and leadership.  
        </SectionP>
        <SectionH3><u>How</u></SectionH3>
        <SectionP justify>
          Companies can participate in Election Day of Service in the following ways:
        </SectionP>
        <StyledGrid>
          <Grid.Row>
            <Grid.Column computer={2} tablet={2} mobile={3}>
              <Image src={RecruitIcon} size='tiny' circular />
            </Grid.Column>
            <Grid.Column computer={10} tablet={10} mobile={11} stretched style={{ alignSelf: 'left' }}>
              <SectionP>Mobilize employees and customers to serve as poll workers</SectionP>
            </Grid.Column> 
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={2} tablet={2} mobile={3}>
              <Image src={SpaceIcon} size='tiny' circular />
            </Grid.Column> 
            <Grid.Column computer={10} tablet={10} mobile={11} stretched style={{ alignSelf: 'left' }}>
              <SectionP>Offer physical space for voting centers (such as office lobbies or warehouses)</SectionP>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={2} tablet={2} mobile={3}>
              <Image src={EngageIcon} size='tiny' circular />
            </Grid.Column> 
            <Grid.Column computer={10} tablet={10} mobile={11} stretched style={{ alignSelf: 'left' }}>
              <SectionP>Engage in-house attorneys to support election workers with legal advice</SectionP>
            </Grid.Column>
          </Grid.Row>
        </StyledGrid>
        <SectionP justify>
          Interested in learning more? Send us an email at <StyledA href="mailto:service@civicalliance.com">service@civicalliance.com</StyledA>.
        </SectionP>
      </Section>
      <Section mode="light">
        <SectionH4>Election Day of Service was developed in collaboration with the following organizations and institutions:</SectionH4>
        <FoundingDayGrid centered>
          { data.allContentfulCivicAllianceCompany.nodes
              .filter(company => company.commitments.includes("Election Day of Service Development"))
              .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))                
              .map(company => 
                <Grid.Column key={company.id} title={company.name} style={{padding: ".75rem"}}>
                  <PartnerLogo src={company.logo.file.url} alt={company.name} logosize={company.logoSize} />
                </Grid.Column>
              )
          }
        </FoundingDayGrid>
      </Section>
      <Section mode="light" relaxedPadding>
        <SectionH4>Election Day of Service Participating Companies:</SectionH4>
        <ResponsiveGrid centered>
          { data.allContentfulCivicAllianceCompany.nodes
              .filter(company => company.commitments.includes("Election Day of Service"))
              .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
              .map(company => 
                <Grid.Column key={company.id} title={company.name}>
                  <PartnerLogo src={company.logo.file.url} alt={company.name} logosize={company.logoSize}/>
                </Grid.Column>
              )
          }
        </ResponsiveGrid>
      </Section>
    </Layout>
  
  );
};

export const query = graphql`
  query {
    allContentfulCivicAllianceCompany(filter: {commitments: {in: ["Election Day of Service", "Election Day of Service Development"]}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        logoSize
        logo {
          file {
            fileName
            url
          }
        }
      }
    }
  }
`;